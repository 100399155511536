import React, { useContext, useEffect } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { AppStateContext } from 'src/context/AppStateProvider';

import usePopupDialog from 'src/hooks/usePopupDialog';
import AnswerSurveyQuestionPopup from 'src/components/popups/AnswerSurveyQuestionPopup';

import useSurveyLink from 'src/hooks/useSurveyLink';

import { LogoCentered } from 'kalanso-plugin-logos';

import ArtDirectedBackground from 'src/components/art-direction/CollageOne';
import SEO from 'src/components/SEO';

import useWebinarRegistrationThankYouVideo from 'src/hooks/useWebinarRegistrationThankYouVideo';

import Headline from 'gatsby-theme-kalanso-ui/src/components/text/Headline';
import SubHeadline from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';
import Emphatic from 'gatsby-theme-kalanso-ui/src/components/text/Emphatic';
import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';
import Underlined from 'gatsby-theme-kalanso-ui/src/components/text/Underlined';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';
import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import _sharedStyles from './success.module.scss';
import _thankYouStyles from './thank-you.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';
import _videoStyles from 'gatsby-theme-kalanso-ui/src/styles/video.module.scss';

const RegistrationThankYouPage = () => {
  const { url: linkToSurvey } = useSurveyLink();

  const [, dispatch] = useContext(AppStateContext);

  const [showPopup, dismissPopup] = usePopupDialog();

  const thankYouVideo = useWebinarRegistrationThankYouVideo();

  useEffect(() => {
    dispatch({ type: 'ACTIVATE_POPUP' });

    return () => {
      dispatch({ type: 'DISMISS_POPUP' });
    };
  }, [dispatch]);

  const surveyLinkImageData = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "survey-link--post--2820x2364.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <SEO title="CONGRATS!!" />

      {showPopup && <AnswerSurveyQuestionPopup onDismiss={dismissPopup} />}

      <Container
        fullScreen={true}
        Background={ArtDirectedBackground}
        className={`${_utils.flexJustifyCenter}`}
      >
        <Row justify="Center">
          <Col>
            <header className={`${_sharedStyles.head}`}>
              <LogoCentered />
            </header>
          </Col>
        </Row>

        <Row
          grow="1"
          align="Center"
          justify="CenterTabletAndUp"
          className={`${_utils.mt64x}`}
        >
          <Col align="Center">
            <Headline
              className={`${_sharedStyles.headline} ${_thankYouStyles.headline}`}
            >
              CONGRATS, YOU'RE BOOKED!
            </Headline>
            <SubHeadline
              className={`${_sharedStyles.subheadline} ${_thankYouStyles.subheadline}`}
            >
              Please Watch This Quick Video To Prepare You For The Training...
            </SubHeadline>

            {/* Thank You Video */}
            <div
              className={`${_videoStyles.iframeWrapper}`}
              dangerouslySetInnerHTML={{ __html: thankYouVideo.iframe }}
            />

            <br />
            <br />
          </Col>
        </Row>
      </Container>

      <Container className={`${_utils.flexJustifyCenter}`}>
        <Row justify="Center">
          <Col className={`${_utils.mt32x} ${_thankYouStyles.script}`}>
            <h1 className={`${_thankYouStyles.scriptHeadline}`}>
              WAIT!
            </h1>
            <h1 className={`${_thankYouStyles.scriptSubheadline}`}>
              Before you show up...
            </h1>
            <p className={`${_thankYouStyles.paragraph}`}>
              Hey, my name is Pat and I want to thank you so much for
              registering for the{' '}
              <Emphatic>secrets of successful growers</Emphatic> training!!!
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              This training is gonna be so much fun!
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              In fact, I'm still putting the finishing touches on it as we
              speak...
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              I wanted to mention a couple of things real quick to help you
              prepare so you know what to expect...
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <Highlighted>
                <strong>NUMBER ONE:</strong>
              </Highlighted>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <em>Mark your calendar for Training Day!</em>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              It's easy to forget...
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              So decide NOW that you're gonna show up and mark it on your
              calendar.
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <Highlighted>
                <strong>NUMBER TWO:</strong>
              </Highlighted>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              As you saw on the Registration Page...
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              We're going to cover a ton of brand new, cutting edge secrets and
              strategies, including:
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <em>
                <Underlined>
                  <strong>Secret #1:</strong>
                </Underlined>{' '}
                The Critical ROLE Every Successful Grower Holds In Their
                Garden...
              </em>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <em>
                <Underlined>
                  <strong>Secret #2:</strong>
                </Underlined>{' '}
                The Truth About What Successful Growers CREATE Like Their Life
                Depends On It (Because It Does)...
              </em>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <em>
                <Underlined>
                  <strong>Secret #3:</strong>
                </Underlined>{' '}
                The Missing Ingredient Each Successful Grower Has Leveraged To
                Build Healthy, Resilient, Lasting Soil...
              </em>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              I’m packing a whole lot into the short time we have together, so I
              might be going a little fast on the training to get it all in.
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              The whole goal of this training to ensure your success as a grower
              and to help you shortcut the process.
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              As someone who lives and breathes this stuff every day, and is
              constantly working on new and better and faster ways to do
              something...
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              I’m obsessed with seeing what works and what doesn’t and then
              sharing all that with you.
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              By the end of the training, you’re going to know exactly what to
              do to:
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <strong>
                * Start each and every growing season off with a bang
              </strong>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <strong>* Create soil that stands the test of time</strong>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <strong>* And maximize plant health</strong>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <em>(This is going to be a total game-changer, I promise.)</em>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              <Highlighted>
                <strong>NUMBER THREE:</strong>
              </Highlighted>
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              I want to be able to customize the program to YOU and help you
              overcome any obstacle or roadblock you might be experiencing right
              now.
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>
              So if you could answer this one question for me, that would be
              awesome!
            </p>
            <p className={`${_thankYouStyles.paragraph}`}>Here goes...</p>
            <a href={linkToSurvey}>
              <Img
                alt="What's Your Number #1 Biggest Challenge When It Comes To Your Soil?"
                fluid={surveyLinkImageData.file.childImageSharp.fluid}
              />
            </a>
            <br />
            <br />
            <a
              href={linkToSurvey}
              className={`${_thankYouStyles.cta} ${_buttonStyles.asButton} ${_buttonStyles.button} ${_buttonStyles.secondary}`}
            >
              YES! I Want To Submit My Answer...
            </a>
            <br />
            <br />

            <a
              href={linkToSurvey}
              className={`${_thankYouStyles.cta} ${_utils.textCenter}`}
            >
              YES! I Want To Submit My Answer...
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};

RegistrationThankYouPage.propTypes = {};

RegistrationThankYouPage.defaultProps = {};

export default RegistrationThankYouPage;
