import { useStaticQuery, graphql } from 'gatsby';

const useWebinarRegistrationThankYouVideo = () => {
  const data = useStaticQuery(
    graphql`
      query {
        vimeoVideo(id: { eq: "496647353" }) {
          title
          url
          iframe
          id
        }
      }
    `,
  );

  // Get the currently published webinar replay
  return data.vimeoVideo;
};

export default useWebinarRegistrationThankYouVideo;
