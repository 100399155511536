import { useStaticQuery, graphql } from 'gatsby';

const useSurveyLink = () => {
  const data = useStaticQuery(
    graphql`
      query ExternalUrlQuery {
        externalUrlsJson(urlType: { eq: "online-workshop-survey" }) {
          id
          urlName
          urlTarget
          urlType
          url
        }
      }
    `,
  );

  return data.externalUrlsJson;
};

export default useSurveyLink;
