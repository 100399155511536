import React from 'react';

import { DialogOverlay, DialogContent } from '@reach/dialog';

import '@reach/dialog/styles.css';

import _dialogStyles from './dialog.module.scss';
import _buttonStyles from 'src/components/buttons/buttons.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const AddToCalendarPopup = ({ onDismiss }) => {
  return (
    <DialogOverlay
      className={_dialogStyles.overlay}
      isOpen={true}
      onDismiss={onDismiss}
    >
      <DialogContent
        className={_dialogStyles.content}
        aria-label="Answer Survey Question"
      >
        <div
          id="popup-answer-survey-question"
          className={`${_dialogStyles.border} ${_utils.relativePosition}`}
        >
          <h2 className={`${_utils.textCenter}`}>
            What's Your Number #1 BIGGEST CHALLENGE When It Comes To Your Soil?
          </h2>

          <div
            className={`${_buttonStyles.center} ${_buttonStyles.fullWidth} ${_buttonStyles.large}`}
          >
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdB2J537hJ3WTBI5Wzs9u1JmkWGSt6kxTGozBEClyyq4R_x6w/viewform?usp=pp_url"
              className={`${_buttonStyles.asButton} ${_buttonStyles.button} ${_buttonStyles.primary}`}
            >
              YES! I Want To Submit My Answer...
            </a>
          </div>

          <button
            id={`popup-invite-friend--close`}
            onClick={onDismiss}
            className={`${_dialogStyles.dismiss} ${_buttonStyles.asLink} ${_buttonStyles.centeredContent}`}
          >
            "I'd rather not say. And even if I did have challenges, they're not
            actually important enough for me to want to tell you..."
            <span role="img" aria-label="Laugh">😂</span>
          </button>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default AddToCalendarPopup;
