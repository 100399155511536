import React from 'react';
import PropTypes from 'prop-types';

import formatClassNames from '../../utils/formatClassNames';

import _cardStyles from './card.module.scss';

const Card = ({ children, rounded, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([
    _cardStyles.card,
    rounded ? _cardStyles.rounded : '',
    className,
  ]);

  return (
    <div className={`${classes}`} {...rest}>
      {children}
    </div>
  );
};

Card.propTypes = {
  rounded: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  rounded: true,
  className: ''
};

export default Card;
