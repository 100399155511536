import React, { useContext } from 'react';
import { AppStateContext } from 'src/context/AppStateProvider';

import SEO from 'src/components/SEO';

import Layout from 'src/components/Layout';
import ProtectedRoute from 'src/components/pages/ProtectedRoute';

import Redirecting from 'src/components/pages/Redirecting';
import RegistrationThankYouPage from 'src/components/pages/success/RegistrationThankYouPage';
import OrderThankYouPage from 'src/components/pages/success/OrderThankYouPage';

import { Router } from '@reach/router';

const SuccessPage = () => {
  const [{ userRegisteredForWebinar }] = useContext(AppStateContext);

  return (
    <Layout>
      <SEO title={"SUCCESS!!"} hideFromSEO={true} />

      <Router basepath="/success">
        <ProtectedRoute
          path="/youre-registered"
          component={RegistrationThankYouPage}
          test={userRegisteredForWebinar}
        />

        <ProtectedRoute
          path="/you-rock/:pid"
          component={OrderThankYouPage}
        />

        {/* Catch All Route */}
        <ProtectedRoute path="/*" component={Redirecting} test={false} />
      </Router>
    </Layout>
  );
};

export default SuccessPage;
