import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';

const PreHeadline = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.preheadline, className])

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default PreHeadline;
