import React from 'react';
import { navigate } from 'gatsby';
import { canUseDOM } from 'kalanso-plugin-utils';

const ProtectedRoute = ({ component: Component, test = true, ...rest }) => {
  if (!canUseDOM()) {
    return null;
  }

  if (!test) {
    navigate(`/register`);

    return null;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
