import { useEffect, useContext, useCallback } from 'react';
import { useWindowSize } from '@reach/window-size';
import { useMouseInViewport } from 'kalanso-plugin-hooks';
import { AppStateContext } from 'src/context/AppStateProvider';

const usePopupDialog = () => {
  const { width, height } = useWindowSize();

  const [{ isEligibleForPopup, isShowingPopup }, dispatch] = useContext(
    AppStateContext,
  );

  const isMouseInViewport = useMouseInViewport(height, width);

  useEffect(() => {
    // Bail if user's mouse is in viewport, but keep the current state
    if (isMouseInViewport) {
      return;
    }

    // Bail if user already dismissed popup
    if (!isEligibleForPopup) {
      dispatch({ type: 'HIDE_POPUP' });
      return;
    }

    // Bail if user's screen size is below 1024, but don't mark
    // the popup as ineligible
    if (width < 1024) {
      dispatch({ type: 'HIDE_POPUP' });
      return;
    }

    // Show popup when user's mouse leaves viewport
    if (!isMouseInViewport && !isShowingPopup) {
      dispatch({ type: 'SHOW_POPUP' });
    }
  }, [
    width,
    isMouseInViewport,
    isEligibleForPopup,
    isShowingPopup,
    dispatch,
  ]);

  const dismissPopup = useCallback(() => {
    dispatch({ type: 'DISMISS_POPUP' });
  }, [dispatch]);

  return [isShowingPopup, dismissPopup];
};

export default usePopupDialog;
