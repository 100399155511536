import React, { useEffect } from 'react';

import { useFetch } from 'kalanso-plugin-hooks';

import useCustomerToken from 'src/hooks/useCustomerToken';

import currencyFormatter, {
  formatterWithCents,
} from 'src/utils/currencyFormatter';

import PreHeadline from 'gatsby-theme-kalanso-ui/src/components/text/PreHeadline';
import Headline from 'gatsby-theme-kalanso-ui/src/components/text/Headline';
import SubHeadline from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';
import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';
import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Card from 'gatsby-theme-kalanso-ui/src/components/card/Card';
import Table from 'gatsby-theme-kalanso-ui/src/components/table/Table';
import TableHeader from 'gatsby-theme-kalanso-ui/src/components/table/TableHeader';
import TableRow from 'gatsby-theme-kalanso-ui/src/components/table/TableRow';

import { LogoCentered } from 'kalanso-plugin-logos';

import _orderThankYouStyles from './order-thank-you.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _textStyles from 'gatsby-theme-kalanso-ui/src/components/text/text.module.scss';

const OrderThankYouPage = ({ pid }) => {
  const { token } = useCustomerToken();

  const [{ pending, success, payload }, getOrderSummary] = useFetch(
    '/api/fetch-order-summary',
    {
      method: 'POST',
      body: JSON.stringify({
        paymentIntentIds: [pid],
      }),
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );

  const { summary = {} } = payload || {};

  useEffect(() => {
    getOrderSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coupon = (summary.coupons || []).find(
    (c) => c.name === 'Free Shipping',
  );

  return (
    <Container>
      <Row justify="Center">
        <Col>
          <header className={`${_orderThankYouStyles.header} ${_utils.mb16x}`}>
            <LogoCentered fill="#000000" />
          </header>
        </Col>
      </Row>

      <Row justify="Center">
        <Col align="Center" grow="1">
          <PreHeadline className={`${_orderThankYouStyles.preheadline}`}>
            You're Gonna Love What We've Got For You
          </PreHeadline>

          <Headline className={`${_orderThankYouStyles.headline}`}>
            Congrats - Your Order Is Confirmed!
          </Headline>

          <SubHeadline
            className={`${_orderThankYouStyles.subheadline} ${_utils.mb32x}`}
          >
            You've Made A Smart Decision! We've Helped Thousands Of People Grow
            ANYTHING They Want With Confidence...{' '}
            <Highlighted>Are You The Next One?</Highlighted>
          </SubHeadline>
        </Col>
      </Row>

      <Row justify="Center">
        <Col align="Center" grow="1">
          <Card className={`${_orderThankYouStyles.summary}`}>
            {pending && <p>Hang tight while we get your order summary...</p>}
            {success && summary.prices && (
              <>
                <p className={`${_utils.mb32x}`}>
                  OK... here's what you ordered:
                </p>
                <Table>
                  <TableHeader columns={['Line Item', 'Value']} />
                  {summary.prices.map((price, index) => (
                    <TableRow key={`price-${index}`}>
                      <Row className={`${_orderThankYouStyles.row}`}>
                        <Col className={`${_utils.mr64x}`}>{price.product.name}</Col>
                        <Col>
                          <strong>
                            {currencyFormatter.format(price.unit_amount / 100)}
                          </strong>
                        </Col>
                      </Row>
                    </TableRow>
                  ))}
                  {summary.shipping.map((rate, index) => (
                    <TableRow key={`shipping-${index}`}>
                      <Row className={`${_orderThankYouStyles.row}`}>
                        <Col>{rate.carrier_nickname}</Col>
                        <Col shrink="0">
                          {Boolean(coupon) ? (
                            <>
                              <strong className={`${_utils.flex} ${_utils.flexJustifyEnd}`}>
                                <span
                                  className={`${_textStyles.strikethrough}`}
                                >
                                  {formatterWithCents.format(
                                    rate.shipping_amount,
                                  )}
                                </span>{' '}
                              </strong>
                              <strong>
                                <span className={`${_textStyles.redColor}`}>
                                  {coupon.name}
                                </span>
                              </strong>
                            </>
                          ) : (
                            <strong>
                              {formatterWithCents.format(rate.shipping_amount)}
                            </strong>
                          )}
                        </Col>
                      </Row>
                    </TableRow>
                  ))}
                </Table>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderThankYouPage;
